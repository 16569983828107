import React, { useState, useEffect } from 'react'

import '../../DataGrid.css';
import gridLogo from "../../../Images/grid.svg";
import ComponentGroupLookupForm from './ComponentGroupLookupForm';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';
import { usePrivileges } from '../../../hooks/usePrivileges';

import useAlert from '../../Alerts/useAlert';

// Styling
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

function ComponentGroupLookupGrid(props) {

    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [pageSize, setPageSize] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [Alertopen, AlertsetOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');
    const [checked, setChecked] = React.useState(true);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const { admin } = usePrivileges();

    const { setAlert } = useAlert();

    const handleChange = (event) => {
        setChecked(event.target.checked);
        ActivateOrInactivateComponentLookup();
    };

    const DeleteRow = (params) => {
        return (
            <strong>
                <button type="button" style={{ width: "80%" }} className="btn btn-outline-primary btn-sm" 
                disabled={params.row.active ? true : false}
                onClick={() => {
                    ActivateOrInactivateComponentLookup(params.row, true);
                }}>Active</button>

                <button type="button" style={{ width: "80%" }} className="btn btn-outline-danger btn-sm" 
                disabled={params.row.active ? false : true}
                onClick={() => {
                    ActivateOrInactivateComponentLookup(params.row, false);
                }}>Inactive</button>
            </strong>
        )
    }

    const EditRow = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openEditComponent(params.row);
                }}>Edit</button>
            </strong>
        )
    }

    var _column = [
        {
            field: 'site',
            headerName: 'Site',
            width: 90,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'language',
            headerName: 'Language',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'componentGroupName',
            headerName: 'Component Group Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 80,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'modifiedDate',
            headerName: 'ModifiedDate',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    // TODO: What roles should this column be visible for?
    // props.PostData.roleId != 3
    if (admin) {
        _column.push(
            {
                field: '-',
                headerName: 'Action(Edit)',
                width: 100,
                renderCell: EditRow,
                disableClickEventBubbling: true,
                headerAlign: 'center',
                align: 'center'
            });
    }

    if (admin) {
        _column.push({
            field: '/',
            headerName: 'Action(Active/Inactive)',
            width: 250,
            renderCell: DeleteRow,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: 'left'
        });
    }

    var columns: GridColDef[] = _column;


    const openEditComponent = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openAddComponent = (params) => {
        setOpen(true);
        setSelectedValue("");
    };

    const handleFormClose = (response) => {
        if (response != null && response == true) {
            LoadComponentGroupLookupList();
        }
        setOpen(false);
    };

    const handleAlertClose = (response) => {
        AlertsetOpen(false);
    };

    const ActivateOrInactivateComponentLookup = async (rowData, isActive) => {
        const ComponentMapFields = {
            ComponentGroupLookupId: rowData.componentGroupLookupId,
            ModifiedBy: username,
            Active: isActive
        };

        await fetchDataAuthenticated(_apiUrl + 'ActiveInactiveComponentGroupLookup', "POST", JSON.stringify(ComponentMapFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then((response) => {
                setAlert("success", "Success", response.message);
                LoadComponentGroupLookupList();
                AlertsetOpen(false);
            })
            .catch(error => {
                AlertsetOpen(false);
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    const LoadComponentGroupLookupList = async () => {

        // TODO: Implement site somewhere
        // props.PostData.siteName
        await fetchDataAuthenticated(_apiUrl + "ComponentGroupLookupDetails/HCCA")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then((data) => {
                setTableData(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    useEffect(() => {
        LoadComponentGroupLookupList();
    }, []);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-9 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    Component Group Lookup
                </div>
                <div className='col-md-3'>
                    {/*TODO: implement this correctly: props.PostData.roleId != 3*/}
                    {admin && <button type="button" className="btn btn-outline-primary btn-primary-label" onClick={openAddComponent}>New Component Lookup</button>}
                </div>
            </div>

            <Box>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.componentGroupLookupId + row.componentGroupName}
                />
            </Box>

            <Dialog open={open} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">Component Group Lookup</DialogTitle>
                <ComponentGroupLookupForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>

            <Dialog
                open={Alertopen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this item?
                    </DialogContentText>
                </DialogContent>
                <div className='container-fluid row mt-3 mb-3 '>
                    <div className='col-md-3'></div>
                    <div className='col-md-9 row'>
                        <div className='col-md-6'>
                            <button type="button" className="btn btn-outline-secondary  btn-sm" onClick={handleAlertClose}>Cancel</button>
                        </div>
                        <div className='col-md-6'>
                            <button type="button" className="btn btn-outline-danger  btn-sm" onClick={ActivateOrInactivateComponentLookup}>Delete</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default ComponentGroupLookupGrid;
